.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-heading {
  text-decoration: none;
}
.home-container01 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-social-bar {
  width: 542px;
  display: flex;
  padding-top: 48px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: 106px;
  padding-bottom: 48px;
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-icon {
  width: 25px;
  height: 25px;
  margin-right: 32px;
  text-decoration: none;
}
.home-link01 {
  display: contents;
}
.home-icon02 {
  width: 25px;
  height: 25px;
  text-decoration: none;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link03 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-mobile-menu {
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-tripleunit);
  position: relative;
  align-items: flex-start;
  animation-name: none;
  flex-direction: column;
  animation-delay: 0s;
  background-color: #fff;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon04 {
  width: 24px;
  height: 24px;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link04 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link05 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link06 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-social-bar1 {
  width: 100%;
  display: flex;
  padding: 48px;
  padding-bottom: 48px;
  justify-content: center;
}
.home-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.home-icon08 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.home-icon10 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.home-icon12 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.home-icon14 {
  width: 16px;
  height: 16px;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f1f1;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #f1f1f1;
}
.home-hero-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading2 {
  color: rgb(4, 4, 4);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cta-btn-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #c9c9c9;
}
.home-text01 {
  color: #101010;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-black);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-image {
  width: 40%;
  object-fit: cover;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  border-width: 0px;
  background-color: var(--dl-color-gray-white);
}
.home-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-about-me {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text02 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text03 {
  text-align: center;
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-500);
  border-style: double;
  border-width: 2px;
}
.home-text04 {
  height: 96px;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 21px;
}
.home-gallery {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: var(--dl-space-space-unit);
  position: relative;
  max-width: 1400px;
  padding-bottom: var(--dl-space-space-fiveunits);
  grid-template-columns: 1fr 1fr 1fr;
}
.home-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-link08 {
  display: contents;
}
.home-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-decoration: none;
}
.home-text05 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text06 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}
.home-gallery-card1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container08 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHdvb2RzfGVufDB8fHx8MTYyNjQ0NzU3Mw&ixlib=rb-1.2.1&w=1000');
  background-position: center;
}
.home-link09 {
  display: contents;
}
.home-image2 {
  width: 100%;
  height: 546px;
  object-fit: cover;
  text-decoration: none;
}
.home-text07 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-container09 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text10 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}
.home-gallery-card2 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container10 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-link10 {
  display: contents;
}
.home-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1581591524425-c7e0978865fc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxDYW1lcmF8ZW58MHx8fHwxNzA1ODY1MTQzfDA&ixlib=rb-4.0.3&q=80&w=1500');
  background-position: center;
}
.home-text11 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text14 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-email {
  font-style: normal;
  font-family: "Overpass";
  font-weight: 700;
  text-transform: lowercase;
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link11 {
  display: contents;
}
.home-icon16 {
  width: 62px;
  height: 24px;
  text-decoration: none;
}
.home-link12 {
  display: contents;
}
.home-icon18 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon20 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-link13 {
  font-size: 16px;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  text-transform: capitalize;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-heading {
    text-align: center;
  }
  .home-heading1 {
    text-align: center;
  }
  .home-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-hero-text-container {
    width: 80%;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading2 {
    text-align: center;
  }
  .home-text {
    text-align: center;
  }
  .home-image {
    width: 100%;
  }
  .home-text02 {
    text-align: center;
  }
  .home-text04 {
    text-align: center;
  }
  .home-gallery {
    grid-template-columns: 1fr 1fr;
  }
  .home-container11 {
    height: 281px;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    position: relative;
  }
  .home-social-bar {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-links-container {
    display: none;
  }
  .home-social-bar1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-cta-btn {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-cta-btn1 {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-heading-container {
    width: 100%;
  }
  .home-gallery {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-gallery-card {
    flex-direction: column;
  }
  .home-gallery-card1 {
    flex-direction: column;
  }
  .home-gallery-card2 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .home-social-bar {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-social-bar1 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-gallery {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-image1 {
    height: 10rem;
  }
  .home-container08 {
    height: 10rem;
  }
  .home-image2 {
    height: 168px;
  }
}
